import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { ToastPlugin, ModalPlugin, VBTogglePlugin, BInputGroup } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import { abilitiesPlugin, Can } from '@casl/vue'
import ability from '@/libs/acl/ability'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from 'vee-validate'
import { Form, FormItem } from 'element-ui'
import * as rules from 'vee-validate/dist/rules'
import { format, setCursor, event } from '@/helpers/mask-currency'
import { assign } from '@/helpers/utils'

import router from './router'
import store from './store'
import App from './App.vue'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import './global/index'

require('@core/assets/fonts/feather/iconfont.css')
// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// Preview Image
Vue.use(VueViewer)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('Can', Can)

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})
Vue.use(VBTogglePlugin)

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.directive('mask-currency', (el, binding) => {
  if (binding.value.type !== 'currency') return
  const input = el
  if (!input.value) { return }
  const defaults = {
    prefix: '',
    suffix: '',
    thousands: '.',
    precision: 0,
  }
  const opt = assign(defaults, binding.value)

  // v-money used on a component that's not a input
  if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
    const els = el.getElementsByTagName('input')
    if (els.length !== 1) {
      // throw new Error("v-money requires 1 input, found " + els.length)
    } else {
      // eslint-disable-next-line no-param-reassign, prefer-destructuring
      el = els[0]
    }
  }

  // eslint-disable-next-line no-param-reassign
  el.oninput = function () {
    let positionFromEnd = el.value.length - el.selectionEnd
    if (el.value === '') {
      // eslint-disable-next-line no-param-reassign
      el.value = ''
    } else {
      // eslint-disable-next-line no-param-reassign
      const value = format(el.value, opt)
      el.value = value
      positionFromEnd = Math.max(positionFromEnd, opt.suffix.length) // right
      positionFromEnd = el.value.length - positionFromEnd
      positionFromEnd = Math.max(positionFromEnd, opt.prefix.length + 1) // left
      setCursor(el, positionFromEnd)
    }
    el.dispatchEvent(event('change')) // v-model.lazy
  }

  // eslint-disable-next-line no-param-reassign
  el.onfocus = function () {
    setCursor(el, el.value.length - opt.suffix.length)
  }

  el.oninput()
  el.dispatchEvent(event('input'))
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
// require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
